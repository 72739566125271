import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import colors from '../../constants/colors';
import twitterIcon from '../../assets/pictures/contact-twitter.png';
import igIcon from '../../assets/pictures/contact-ig.png';
import ttIcon from '../../assets/pictures/contact-tt.png';

export interface ContactProps {}

// Props for SocialBox component
interface SocialBoxProps {
    icon: string;
    link: string;
}

// SocialBox component to display social media icons with links
const SocialBox: React.FC<SocialBoxProps> = ({ link, icon }) => {
    return (
        <a rel="noreferrer" target="_blank" href={link}>
            <div className="big-button-container" style={styles.social}>
                <img src={icon} alt="" style={styles.socialImage} />
            </div>
        </a>
    );
};

// Contact component
const Contact: React.FC<ContactProps> = (props) => {
    const [company, setCompany] = useState('');
    const [state, handleSubmit] = useForm("xvgpayld"); // Replace with your Formspree form ID
    const [formMessage, setFormMessage] = useState('All messages get forwarded straight to Mike Ferri');
    const [formMessageColor, setFormMessageColor] = useState('');

    useEffect(() => {
        if (state.succeeded) {
            setFormMessage('Message successfully sent. Thank you!');
            setFormMessageColor(colors.blue);
        } else if (state.errors && Object.keys(state.errors).length > 0) {
            setFormMessage('There was an error sending your message. Please try again.');
            setFormMessageColor(colors.red);
        }
    }, [state.succeeded, state.errors]);
    return (
        <div className="site-page-content">
            {/* Header with contact title and social media icons */}
            <div style={styles.header}>
                <h1>Contact</h1>
                <div style={styles.socials}>
                    <SocialBox
                        icon={igIcon}
                        link={'https://www.instagram.com/montypk_/'}
                    />
                    <SocialBox
                        icon={ttIcon}
                        link={'https://www.tiktok.com/@monty.pk?_t=8oZpu3zN2C6&_r=1'}
                    />
                    <SocialBox
                        icon={twitterIcon}
                        link={'https://x.com/montypk_'}
                    />
                </div>
            </div>
            <div className="text-block">
                <p>
                    To keep up with releases, feel free to send a DM ^^<br /><br />
                    Shoot me a text to stay up to date with releases: <b>(641) 672-7298</b><br />
                    <br />
                    For business inquiries, I would prefer you reach out to my manager below. <br />
                    His name is Mike. He doesn't bite.
                </p>
                <br />
                <p>
                    <b>Email: </b>
                    <a href="mailto:ferri@goodproblems.co">
                        ferri@goodproblems.co
                    </a>
                </p>

                {/* Contact form */}
                <form style={styles.form} onSubmit={handleSubmit}>
                    <label>
                        <p>
                            <b>Your name:</b>
                        </p>
                    </label>
                    <input
                        style={styles.formItem}
                        type="text"
                        name="name"
                        placeholder="Name"
                    />
                    <ValidationError
                        prefix="Name"
                        field="name"
                        errors={state.errors}
                    />
                    <label>
                        <p>
                            <b>Email:</b>
                        </p>
                    </label>
                    <input
                        style={styles.formItem}
                        type="email"
                        name="email"
                        placeholder="Email"
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                    <label>
                        <p>
                            <b>Company (optional):</b>
                        </p>
                    </label>
                    <input
                        style={styles.formItem}
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>
                        <p>
                            <b>Message:</b>
                        </p>
                    </label>
                    <textarea
                        name="message"
                        placeholder="Message"
                        style={styles.formItem}
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                    <div style={styles.buttons}>
                        <button
                            className="site-button"
                            style={styles.button}
                            type="submit"
                            disabled={state.submitting}
                        >
                            {state.submitting ? (
                                <p className="loading">Sending</p>
                            ) : (
                                'Send Message'
                            )}
                        </button>
                        <div style={styles.formInfo}>
                            <p
                                style={{
                                    color: formMessageColor,
                                    marginLeft: '20px',
                                }}
                            >
                                <b>
                                    <sub>
                                        {formMessage
                                            ? formMessage
                                            : 'All messages get forwarded straight to Mike Ferri'}
                                    </sub>
                                </b>
                            </p>
                            <p>
                                <sub>
                                    <span>
                                        <b style={styles.star}>*</b> = required
                                    </span>
                                </sub>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    form: {
        flexDirection: 'column',
        marginTop: 32,
    },
    formItem: {
        marginTop: 4,
        marginBottom: 16,
    },
    socialImage: {
        width: 36,
        height: 36,
    },
    buttons: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formInfo: {
        textAlign: 'right',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingLeft: 24,
    },
    star: {
        paddingRight: 4,
        color: 'red',
    },
    button: {
        minWidth: 184,
        height: 32,
    },
    header: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    socials: {
        marginBottom: 16,
        justifyContent: 'flex-end',
    },
    social: {
        width: 4,
        height: 4,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },
};

export default Contact;