import React, { useState } from 'react';
// @ts-ignore
import IAMF from '../../assets/audio/if_a_man_falls.mp3';
// @ts-ignore
import hostage from '../../assets/audio/this_is_a_hostage_situation.mp3';
// @ts-ignore
import SATS from '../../assets/audio/staring_at_the_sun.mp3';
// @ts-ignore
import IAMFcover from '../../assets/pictures/music/audio/IAMF.png';
import hostagecover from '../../assets/pictures/music/audio/hostage.png';
import SATScover from '../../assets/pictures/music/audio/SATS.png';
import { MusicPlayer } from '../general';
import spotifyIcon from '../../assets/pictures/spotify.png';
import appleIcon from '../../assets/pictures/applemusic.png';

export interface ProjectsProps {}

// Props for SocialBox component
interface SocialBoxProps {
    icon: string;
    link: string;
}

// SocialBox component to display social media icons with links
const SocialBox: React.FC<SocialBoxProps> = ({ link, icon }) => {
    return (
        <a rel="noreferrer" target="_blank" href={link}>
            <div className="big-button-container" style={styles.social}>
                <img src={icon} alt="" style={styles.socialImage} />
            </div>
        </a>
    );
};


const Projects: React.FC<ProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <div style={styles.header}>
                <h1>Music</h1>
                <div style={styles.socials}>
                    <SocialBox
                        icon={spotifyIcon}
                        link={'https://open.spotify.com/artist/3L2O6zqeWf9jdUFVihAB5g?si=I8J7ZvNwSo-ZX3E9Q3aUQA'}
                    />
                    <SocialBox
                        icon={appleIcon}
                        link={'https://music.apple.com/us/artist/monty-pk/1446985385'}
                    />
                </div>
            </div>
            <div className="text-block">

            </div>
            <h2>if a man falls</h2>
            <br />
            <p>
                got me asking myself in a whistle-stop town...
            <br />
                if a man falls, does he even make a sound?
            </p>
            <br />
            <div className="captioned-image">
                <img src={IAMFcover} alt="if a man falls" />
                <p>
                    <sub>
                        <b>Figure 1:</b> Cover art shot in Amboy, CA.
                    </sub>
                </p>
            </div>


            <MusicPlayer
                src={IAMF}
                title="if a man falls"
                subtitle="monty.pk"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />

            <br />
            <br />
            <br />
            <h2>this is a hostage situation!</h2>
            <br />
            <p>
                this one may have been in poor taste
            </p>
            <br />
            <div className="captioned-image">
                <img src={hostagecover} alt="this is a hostage situation!" />
                <p>
                    <sub>
                        <b>Figure 2:</b> Custom keychain from ap3x4nn3.
                    </sub>
                </p>
            </div>


            <MusicPlayer
                src={hostage}
                title="this is a hostage situation!"
                subtitle="monty.pk"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <br />
            <br />
            <br />
            <h2>STARING AT THE SUN</h2>
            <br />
            <p>
                yelling, hence the all caps
            </p>
            <br />
            <div className="captioned-image">
                <img src={SATScover} alt="this is a hostage situation!" />
                <p>
                    <sub>
                        <b>Figure 3:</b> Ignore the fleshy color. That is the sun.
                    </sub>
                </p>
            </div>


            <MusicPlayer
                src={SATS}
                title="STARING AT THE SUN"
                subtitle="monty.pk"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <br />
            <br />
            <br />
            <p>
                there's a few more. check spotify.<br />all this for a damn website.<br />who even looks at these.
            </p>

        </div>
    );
};

// Styles for the component
const styles: StyleSheetCSS = {
    form: {
        flexDirection: 'column',
        marginTop: 32,
    },
    formItem: {
        marginTop: 4,
        marginBottom: 16,
    },
    socialImage: {
        width: 36,
        height: 36,
    },
    buttons: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formInfo: {
        textAlign: 'right',

        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingLeft: 24,
    },
    star: {
        paddingRight: 4,
        color: 'red',
    },
    button: {
        minWidth: 184,
        height: 32,
    },
    header: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    socials: {
        marginBottom: 16,
        justifyContent: 'flex-end',
    },
    social: {
        width: 4,
        height: 4,
        // borderRadius: 1000,

        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },
};

export default Projects;